<template>
  <div>
    <reports-contract-team
      v-if="reportDepartmentHS && sumData && sumDataChart"
      :sum-data="sumData"
      :sum-data-chart="sumDataChart"
      :report-department-h-s="reportDepartmentHS"
      :report-department-h-d="reportDepartmentHD"
      :custom-field-report="customFieldReport"
      :time-filter.sync="timeFilter"
      :names-with-moste-vote="namesWithMosteVote"
      :department-id="departmentId"
      :debt-collection-report-list="debtCollectionReportList"
      :fetch-debt-collection-detail-report="fetchDebtCollectionDetailReport"
    />
    <chart-total-amount-team
      :sum-department="sumDepartment"
      :time-report-department="timeReportDepartment"
    />
    <!--    <other-charts-->
    <!--      v-if="reportOtherChartsData"-->
    <!--      :data="reportOtherChartsData"-->
    <!--    />-->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import reportStoreModule from '@/views/reports/reportStoreModule'
import ReportsContractTeam from '@/views/overviewTeam/ReportsContractTeam.vue'
import ChartTotalAmountTeam from '@/views/overviewTeam/ChartTotalAmountTeam.vue'
import { getUserData } from '@/auth/utils'
import metaStoreModule from '@/views/settings/parameters/metaStoreModule'
import useOverviewTeam from './useOverviewTeam'

export default {
  components: {
    ReportsContractTeam,
    ChartTotalAmountTeam,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    this.fetchSumMe()
    this.fetchSumMeChart()
    this.fetchSumDepartment()
    this.fetchDepartmentVote()
  },
  mounted() {
    window.__overviewTeam = this
  },
  methods: {},
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const META_STORE_MODULE_NAME = 'meta'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    if (!store.hasModule(META_STORE_MODULE_NAME)) store.registerModule(META_STORE_MODULE_NAME, metaStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
      if (store.hasModule(META_STORE_MODULE_NAME)) store.unregisterModule(META_STORE_MODULE_NAME)
    })
    const userData = getUserData()

    return {
      ...useOverviewTeam({ departmentId: userData.department_id }),
      departmentId: userData.department_id,
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/vue/libs/vue-flatpicker.scss";
@import "~flatpickr/dist/plugins/monthSelect/style.css";

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.reports-contracts {
}
</style>
